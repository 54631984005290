.video-embed {
    position: relative;
}

.plyr__controls__item:before {
    display: none;
}

.plyr__control:before {
    display: none;
}

.plyr:fullscreen video {
    width: inherit !important;
}

.js-video-started,
.js-loading {
    .video-embed-container-still-image {
        display: none;
    }
}

button.js-video-handler-play-button:hover {
    background-color: unset !important;
}

.plyr__poster {
    pointer-events: none;
}
